// Grid system
.main {
  @include make-sm-column($main-sm-columns);

  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}

.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}

.row-flex {
  display: flex;
  flex-wrap: wrap;

  &::before,
  &::after {
    content: none;
  }
}

.gutter-xs-narrow {
  @media (max-width: $screen-xs-max) {
    margin-left: -7.5px;
    margin-right: -7.5px;

    >[class*="col-"] {
      padding-right: 7.5px;
      padding-left: 7.5px;
    }
  }
}

.column-break {
  width: 100%;
}
