// **
// * Home
// **
@keyframes linearwipe{
  from { width: 0; }
}

.lead-wrapper {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: $black;
  overflow: hidden;
  @media (min-width: $screen-sm-min) {
    position: fixed;
    left: 60px;
  }
}

.content-wrapper {
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  position: relative;
  @media (min-width: $screen-sm-min) {
    //margin-top: 100%;
  }
}
//Here
.home-gallery {
  position: relative;
  overflow: hidden;
  height: 100%;
  height: 100%;
  //background-color: $black;
/*
  .flickity-viewport {
    left: 0;
    right: 0;
    position: fixed;
    @media (min-width: $screen-sm-min) {
      //left: 60px;
    }
  }
*/
  .img-container {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform-style: preserve-3d;
  }

  .home-gallery-image,
  .home-gallery-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    transition: opacity 0.4s;
    opacity: 0;
    transform-style: preserve-3d;
    @media (max-width: $screen-xs-max) and (orientation: landscape) {
      min-width: 100%;
      width: 100%;
      min-height: auto;
      height: auto;
    }
    @media (max-width: $screen-xs-max) and (orientation: portrait) {
      min-height: 100%;
      height: 100%;
      min-width: auto;
      width: auto;
    }
    &.flickity-lazyloaded,
    &.flickity-lazyerror {
      opacity: 1;
    }
  }

  .home-gallery-video {
    opacity: 1;
  }

  @supports (object-fit: cover){
    .home-gallery-image,
    .home-gallery-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      min-width: 0;
      min-height: 0;
      object-fit: cover;
      transform: initial;
      transform-style: initial;
    }
  }

  .flickity-slider {
    transform: none !important;
  }

  .gallery-cell {
    width: 100%;
    height:100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    opacity: 0;
    transition: opacity 1s ease-in;
    z-index: 0;

    // Simulated fade animation
    left: 0!important;
    transform: translateX(0) !important;

    .slide-content {
      width: 0px;
      transition: all 0.4s ease-in-out;
      // animation: 0.4s linearwipe 1 reverse;
      z-index: 10;
    }

    &.is-selected {
      opacity: 1;
      z-index: 1;

      .slide-content {
        width: 100%;
        transition: all 0.4s ease-out 0.4s;
        // animation: 0.4s linearwipe 0.4s 1;
      }
    }
  }

  .slide-content-container {
    position: absolute;
    bottom: 15px;
    left: 30 + 15px;
    transform: translateZ(0);
    transform-style: preserve-3d;

    @media (min-width: $screen-sm-min) {
      bottom: 30px;
      left: 150px;
      transition: transform 0.2s cubic-bezier(0.2, 1, 0.3, 1);

      .menu-active & {
        transform: translateX(210px);
      }
    }
  }

  .slide-content {
    color: $white;
    width: 0px;
    height: 60px;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100vw - 60px);

    @media (min-width: $screen-sm-min) {
      max-width: none;
    }

    > a,
    > span {
      display: inline-flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      padding: 0 12px;
    }

    .project-title,
    .project-description {
      display: inline-block !important;
    }

    .project-title {
      margin: 0;
      padding: 0;
      font-size: 18px;
      font-family: $font-family-heading;
      font-weight: bold;
      line-height: 1.2;

      @media (min-width: $screen-sm-min) {
        font-size: 24px;
      }
    }

    .project-description {
      padding: 0;
      margin: 0;
    }

    h1 {
      margin: 0;
    }

    a {
      color: white;
      text-decoration: none;

      &:hover,
      &:focus {
       color: $black;
      }
    }
  }

  .flickity-prev-next-button {
    position: absolute;
    bottom: 30px;
    left: 30px;
    top: auto;
    transform: none;
    width: 60px;
    height: 60px;
    background-color: rgba(white, 0.25);
    transition: transform 0.2s cubic-bezier(0.2, 1, 0.3, 1);
    z-index: 10;

    &.next {
      left: 90px;
    }

    .arrow {
      fill: $white;
    }

    &:hover {
      .arrow {
        fill: $brand-primary;
      }
    }

    .menu-active & {
      @media (min-width: $screen-sm-min) {
        transform: translateX(210px);
      }
    }

    @media (max-width: $screen-xs-max) {
      left: 15px;
      bottom: 30 + 15px;
      width: 30px;
      height: 30px;

      &.next {
        left: 15px;
        bottom: 15px;
      }
    }
  }
}

.home-portal {
  h2 {
    margin-bottom: 20px;
  }

  .portal-article {
    font-size: $font-size-h4;
  }
}

// **
// * Project Pages
// **

#projectsArchive {}

.grid-item {
  margin-bottom: 15px;
  transition: opacity 0.4s cubic-bezier(0.2, 1, 0.3, 1);


  @media (min-width: $screen-sm-min) {
    margin-bottom: 30px;
  }
}

.projects-nav {
  margin-bottom: 30px;
}

.projects-nav-ul {
  padding: 0;

  font-size: 16px;

  @media (min-width: $screen-sm-min) {
    font-size: $font-size-h3;
  }

  > li {
    display: block;
  }

  .btn-link.active {
    color: $black;
    font-weight: 700;

    &:hover {
      color: $black;
    }
  }
}

.project-archive-header {
  @extend .clearfix;
  h1 {
    float: left;
  }
}

.project-archive-views {
  float: right;
  height: 41px;

  margin-bottom: 20px;

  .page-header & {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 0;

    @media (min-width: $screen-sm-min) {
      padding-top: 17px;
    }
  }

  button {
    color: $btn-link-color;
    opacity: 1;
    //transition: opacity 0.4s cubic-bezier(0.2, 1, 0.3, 1)
  }

  button[disabled=disabled],
  button:disabled {
    color: $btn-link-disabled;
    //opacity: 0.5;

    &:hover,
    &:focus,
    &:active {
      color: $btn-link-disabled;
    }
  }

  li:last-child {
    padding-right: 0;
  }
}

.grid-view-toggle,
.list-view-toggle {
  position: relative;
  width: 24px;
  height: 24px;
  display: block;
}

.grid-view-box-row,
.list-view-box-row {
  position: relative;
  width: 24px;
  height: 8px;
  display: block;
  .grid-view-box {
    width: 6px;
    height: 6px;
    border: 1px solid currentColor;
    display: block;
    margin-top: 1px;
    margin-left: auto;
    margin-right: auto;
    &:after,
    &:before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      margin-top: -1px;
      border: 1px solid currentColor;
      display: block;
    }
    &:after {
      left: 0;
    }
    &:before {
      right: 0;
    }
  }
  .list-view-box {
    height: 6px;
    border: 1px solid currentColor;
    display: block;
    margin-top: 1px;
    margin-left: auto;
    margin-right: auto;
  }
}

.projects-list-header {
  margin-bottom: 10px;
  font-size: $font-size-h3;
  .projects-list-sort {
    border-bottom: 1px solid $border-color;
    padding-bottom: 15px;
    //margin-bottom: 30px;
    .btn-link {
      padding: 0;
      text-align: left;
      //@include make-xs-column(12);
      @include make-sm-column(2);
      &:first-child {
        //@include make-xs-column(12);
        @include make-sm-column(4);
      }
    }
  }
}

.project-grid-meta {
  //font-size: $font-size-small;
  h4 {
    margin-top: 0;
    margin-bottom: 0;

    @media (max-width: $screen-xs-max) {
      font-size: 16px;
    }
  }
}

.projects-archive-grid {
  overflow: hidden;
  // transition: height 0.4s linear;

  //.projects-list-header,
  .project-item-meta {
    display: none;
  }
}

.projects-archive-list {
  margin-bottom: 60px;

  .on-list-hidden,
  .projects-nav,
  figure {
    display: none;
  }

  .projects-list-header {
    display: block;

    .projects-list-sort {
      @media (max-width: $screen-xs-max) {
        height: 1px;
        overflow: hidden;
        padding: 0;
      }
    }
  }

  .grid-item {
    margin-bottom: 10px;
  }

  .project-item-meta {
    border-bottom: 1px solid $border-color;
  }

  .not-available {
    color: $btn-link-disabled;
  }

  .project-title {
    // font-family: $font-family-heading;
    // font-size: 16px;

    @media (max-width: $screen-xs-max) {
      margin-bottom: 7px;
    }
  }

  //.project-title,
  .project-status,
  .project-location,
  .project-budget,
  .project-size {
    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }
}

.project-title,
.project-status,
.project-location,
//.project-budget,
.project-size {
  @media (max-width: $screen-xs-max) {
    margin: 0;
  }
}


// **
// * People
// **

.lead-photo {
  margin-bottom: 40px;
}

.bio-accordion,
.awards-accordion {
  border-top: 1px solid $border-color;
  margin-top: 40px;

  .panel+.panel {
    margin: 0;
  }

  .panel {
    box-shadow: 0 0px 0px transparent;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid $border-color;
    border-left: 0;
  }
}

.bio-container p {
  //font-size: $font-size-small;
  line-height: 1.6;

  &.subtitle {
    // color: $alt-text-color;
    //font-size: $font-size-base;
  }
}

.bio-col .bio-container {
  margin-bottom: 30px;

  @media ( min-width: $screen-sm-min ) {
    margin-bottom: 40px;
  }
}

.term-heading {
  margin-bottom: 20px;
}

.bio-list {
  .bio-container {
    border-bottom: 1px solid $border-color;
    padding-bottom: 18px;
    padding-top: 18px;

    .bio-name {
      margin: 0;
    }
  }

  &:first-child {
    .bio-container {
      border-top: 1px solid $border-color;
    }
  }

  &:last-child {
    .bio-container {
      border-bottom: none;
    }
  }
}

.bio-modal-button {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.bio-collapse-toggle {
  position: relative;

  a {
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }
  h3 {
    margin: 0;
  }
}

.modal-bio-description {
  .bio-description {
    @extend %body-text;

    color: $black;
  }
}

.bio-portrait {
  margin-bottom: 15px;
  // min-width: 100%;

  .bio-image {
    width: 100%;
  }

  @media (min-width: $screen-sm-min) {
    margin-bottom: 30px;
  }
}

.bio-name {
  // color: $alt-text-color;
  margin-bottom: 2px;
}

// **
// * Culture
// **
body.page-template-template-culture {
  .wrap.container-fluid {
    max-width: 100%;
    overflow: hidden;
  }

  // .banner {
  //   @media (min-width: $screen-sm-min) {
  //     position: absolute;
  //     z-index: 100;
  //     right: 0;
  //     left: 60px;
  //     width: auto;
  //   }
  // }
}

.section-culture {
  // margin-bottom: 120px;

  &:last-child {
    margin-bottom: 60px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 120px;
    }
  }
}

.section-grid {
  &.layout-flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    &.align-left {
      justify-content: flex-start;
    }

    &::before,
    &::after {
      content: none;
    }

    .grid-item {
      flex: 0 0 auto;
    }
  }

  &.layout-height {
    display: flex;
    flex-wrap: wrap;

    .grid-item {
      display: flex;
      // align-items: center;
      // justify-content: center;
      flex-direction: column;
    }
  }

  .grid-item-content {
    width: 100%;
    backface-visibility: hidden;

    .media-container {
      margin-bottom: 0;
    }
  }

  .grid-text-content,
  .grid-textimage-content {
    @extend %body-text;

    padding-top: 30px;
    padding-bottom: 30px;

    > h2:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .grid-text {
    margin-bottom: 0;
  }
}

// Reduced Motion
// .my-paroller {
//   @media (prefers-reduced-motion: reduce) {
//     transform: none !important;
//   }
// }

.culture-intro-image {
  position: relative;
  padding-top: 100%;
  margin-right: -30px;
  overflow: hidden;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

// Recognition/Awards & Publications

.awards-section {
  margin-bottom: 40px;
  @media (min-width: $screen-sm-min) {
    margin-bottom: 60px;
  }
  .panel-collapse {
    overflow: hidden;
    .row {
      //border-top: 1px solid $border-color;
    }
  }
  .date-column {
    @media (min-width: $screen-md-min) {
      text-align: right;
    }
    .publication-date,
    .award-date {
      @media (max-width: $screen-xs-max) {
        margin-top: 0;
        font-size: 14px;
      }
    }
  }
}

.publication-name,
.award-title {
  border-top: 1px solid $border-color;
  padding-top: 10px;
  margin-top: 0;
}

.publication-name .glyphicon,
.award-title .glyphicon {
  top: -1px;
  font-size: 10px;
}

#map {
  min-height: 400px;
}

.mapbox-container {
  margin-top: 20px;
}

.archive-content {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid $border-color;
}
