// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #fff000;
$black:                 #131414;
$white:                 #fff;
$grey-light:            #e6e7e8;
$grey:                  #6d6e71;
$nav-bg-color:          #424343;
$dark-grey:             #414042;

$border-color:          darken($grey-light, 30%);

// $text-color:            #414042;
$text-color:            $black;
$light-text:            $black;
$alt-text-color:        $black;
$btn-link-color:        $black;
$btn-link-disabled:     $grey-light;

// Links
$link-color:            #807e79;
$link-hover-color:      $black;
$link-hover-decoration: none;

// Type sizes
//$font-size-base:          16px;
$font-size-base:          14px;

$font-size-large:         ceil(($font-size-base * 1.25)); // ~20px
$font-size-small:         ceil(($font-size-base * 0.85)); // ~14px

$font-size-h1:            floor(($font-size-base * 3.725)); // ~52px
$font-size-h2:            floor(($font-size-base * 2.285)); // ~32px
$font-size-h3:            floor(($font-size-base * 1.71428)); // ~25px
$font-size-h4:            ceil(($font-size-base * 1.25)); // ~20px
$font-size-h5:            $font-size-base;
$font-size-h6:            ceil(($font-size-base * 0.875)); // ~14px

$headings-font-weight:    400;
//$headings-font-weight:    700;

$font-family-sans-serif:  neue-haas-grotesk-text, sans-serif;
$font-family-display:     'Helvetica Neue LT Pro Condensed', sans-serif;
$font-family-heading:     'Helvetica Neue LT Pro Condensed', sans-serif;

$line-height-base:        1.4375;

// Nav Stuff
$nav-link-padding:                          0;
$nav-link-hover-bg:                         transparent;

// Page Header
$page-header-border-color: transparent;


// Border-radius: reset
$border-radius-base: 0;

$hr-border: $border-color;
