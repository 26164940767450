/**
 * @license
 * MyFonts Webfont Build ID 3757610, 2019-05-08T18:46:58-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: HelveticaNeueLTPro-MdCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-67-medium-condensed/
 * Copyright: Copyright &#x00A9; 2014 Monotype Imaging Inc. All rights reserved.
 * Licensed pageviews: 250,000
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3757610
 * MyFonts Webfont Build ID 3773505, 2019-06-12T19:23:39-0400
 *
 * You may obtain a valid license at the URLs below.
 *
 * @license
 * MyFonts Webfont Build ID 3775962, 2019-06-19T16:28:35-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: HelveticaNeueLTPro-LtCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-47-light-condensed/
 * Copyright: Copyright &#x00A9; 2014 Monotype Imaging Inc. All rights reserved.
 * Licensed pageviews: 250,000
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3775962
 *
 * © 2019 MyFonts Inc
*/

// MyFonts (Helvetica Neue LT Pro Medium Condensed)
@import url("//hello.myfonts.net/count/39562a");

// MyFonts (Helvetica Neue LT Pro Light Condensed)
@import url("//hello.myfonts.net/count/399dda");

// Adobe Fonts (Neue Haas Grotesk)
// @import url("https://use.typekit.net/ohk6eef.css");

// Neue Haas Grotesk Display Pro 55 Roman
// font-family: neue-haas-grotesk-display, sans-serif;
// font-weight: 500;
// font-style: normal;

// Neue Haas Grotesk Text Pro 55 Roman
// font-family: neue-haas-grotesk-text, sans-serif;
// font-weight: 400;
// font-style: normal;

// Neue Haas Grotesk Text Pro 56 Italic
// font-family: neue-haas-grotesk-text, sans-serif;
// font-weight: 400;
// font-style: italic;

// Neue Haas Grotesk Text Pro 75 Bold
// font-family: neue-haas-grotesk-text, sans-serif;
// font-weight: 700;
// font-style: normal;

@font-face {
  font-family: 'Helvetica Neue LT Pro Condensed';
  font-weight: bold;
  font-style: normal;
  src: url('../fonts/39562A_0_0.eot');
  src: url('../fonts/39562A_0_0.eot?#iefix') format('embedded-opentype'),
       url('../fonts/39562A_0_0.woff2') format('woff2'),
       url('../fonts/39562A_0_0.woff') format('woff'),
       url('../fonts/39562A_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue LT Pro Condensed';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/399DDA_0_0.eot');
  src: url('../fonts/399DDA_0_0.eot?#iefix') format('embedded-opentype'),
       url('../fonts/399DDA_0_0.woff2') format('woff2'),
       url('../fonts/399DDA_0_0.woff') format('woff'),
       url('../fonts/399DDA_0_0.ttf') format('truetype');
}
