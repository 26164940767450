body.loading > #container,
body.loading > .nav-sidebar,
body.loading > .content-info {
  display: none;
}

body > #container,
body > .nav-sidebar {
  //display: block;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  font-smooth: always;
  outline: 0;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
  //line-height: 1.4375;

  //hack, come back to this
  margin-top: 60px;

  @media (min-width: $screen-sm-min) {
    margin-top: 0;
    margin-left: 60px;
  }
  //background-color: $nav-bg-color;
}

body.admin-bar .nav-sidebar .menu,
body.admin-bar .nav-sidebar .menu-toggle {
  top: 32px !important;
}

.error404 {}

a,
button {
  // transition: color 0.2s cubic-bezier(0.2, 1, 0.3, 1);
}

.announcement {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  z-index: 100;
  background: rgba($black, 0.8);
  padding: 15px;
  color: $white;
  max-width: 100%;
  transition: transform 0.2s cubic-bezier(0.2, 1, 0.3, 1);

  @media (min-width: $screen-sm-min) {
    top: 30px;
    left: 30px;
    right: auto;
    max-width: 50%;
  }

  @media (min-width: $screen-md-min) {
    max-width: 33.3333333%;
  }

  .menu-active & {
    @media (min-width: $screen-sm-min) {
      transform: translateX(210px);
    }
  }

  a {
    color: rgba($white, 0.8);

    &:hover,
    &:focus {
      color: $black;
    }
  }

  p {
    font-size: $font-size-h4;
    margin-bottom: 5px;
  }
}

//typography

%body-text {
  font-size: 16px;

  @media (min-width: $screen-sm-min) {
    font-size: 18px;
  }
}

.font-family-condensed {
  font-family: $font-family-heading;
}

.lead {
  line-height: 1.2;
  margin-bottom: $line-height-computed;
  font-family: $font-family-display;
  font-weight: normal;

  @media (min-width: $screen-sm-min) {
    font-size: $font-size-h2;
  }
}

.lead-xxl {
  @extend .lead;

  font-size: 34px;

  @media (min-width: $screen-sm-min) {
    font-size: $font-size-h1;
  }
}

.lead-xl {
  @extend .lead;

  font-size: 28px;

  @media (min-width: $screen-sm-min) {
    font-size: $font-size-h1 - 10;
  }
}

.contact-info,
.content-info {
  font-size: $font-size-small;
}

.page-header {
  color: $black;
}

h1, .h1,
h2, .h2,
.term-heading {
  font-family: $font-family-heading;
  font-weight: bold;
}

h1 {
  @media (max-width: $screen-xs-max) {
    font-size: 34px;
  }
}

h2,
.lead {
  @media (max-width: $screen-xs-max) {
    font-size: 28px;
  }
}

h4 {
  @media (max-width: $screen-xs-max) {
    font-size: 16px;
  }
}

a {
  color: $black;

  &:hover {
    // color: lighten($black, 45%);
    color: $black;
    text-decoration: none;
    // background-repeat: repeat-x;
    // background-image: linear-gradient(to right,#fff000 100%, #fff000 0);
    // background-size: 1px 2px;
    // background-position: 0 1.25em;
    // background-position: 0 calc(1em + 2px);
    background-color: #fff000;
  }
}

.caption {
  color: $grey;
  padding-top: 8px;
  font-size: 14px;
}

.section-header {
  h1, h2, h3 {
    line-height: 1;
    margin-top: 0;
    margin-bottom: 15px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 30px;
    }

  }
}

.list-social {
  font-size: $font-size-large;
}

.project-types {
  font-size: $font-size-small;
  margin-top: 4px;
}

//pjax container
#container {
  background-color: $white;
}

.content-wrapper {
  z-index: 10;
  position: relative;
  padding-top: 40px;
  background-color: $white;

  @media (min-width: $screen-sm-min) {
    padding-top: 80px;
  }
}

.modal-open .content-wrapper {
  z-index: 1100;
}

.main {
  margin-bottom: 30px;
  // @media (min-width: $screen-sm-min) {
  //  margin-bottom: 30px;
  // }
}

header .container-fluid,
.wrap.container-fluid,
.home-gallery .container-fluid,
.content-info .container-fluid {
  max-width: 1440px;
}

.container-fluid {
  @media (min-width: $screen-sm-min) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

figure {
  margin-bottom: 20px;
}


//Logo / Header
.lead-wrapper,
.pre-loading,
.loading {
  .shape-logo {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 60%;
    transform: translateX(-50%) translateY(-50%);
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    opacity: 0.55;
    //transition: opacity 1.5s cubic-bezier(0.2, 1, 0.3, 1);
    z-index: 15;

    @media (min-width: $screen-sm-min) {
      width: 100%;
      position: fixed;
    }
  }
}

.pre-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 999999;

  .pre-loading-logo {
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.2, 1, 0.3, 1);

    &.loaded {
      opacity: 0.55;
    }
  }
}

.pre-loading-logo {
  image-rendering: -webkit-optimize-contrast;
}

// new banner positioning
.banner {
  position: relative;
  width: 100%;
  height: auto;

  &.position-absolute {
    z-index: 1;
    position: fixed;
    width: auto;
    top: 0;
    left: 0;
    right: 0;

    @media (min-width: $screen-sm-min) {
      left: 60px;
    }
  }
}

.shape-logo {
  width: 100%;
  height: 0;
  padding-top: 31.75%;

  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center center;
  //background-size: (980px / 2) (254px / 2);

  @media (min-width: $screen-sm-min) {
    width: 50%;
    padding-top: 13%;
    image-rendering: -webkit-optimize-contrast;
    //background-position: 115% -20px;
  }

  &.position-top-right {
    float: right;
    background-size: 105% auto !important;
    background-position: 50% 100%;

    @media (min-width: $screen-sm-min) {
      background-position: 0 110%;
    }
  }
}


.shape-logo-grey {
  @include img-retina("../images/shape-logo-grey-A.png","../images/shape-logo-grey-A@2x.png",980px,254px);
}

.shape-logo-black {
  @include img-retina("../images/logo-full-black.png","../images/logo-full-black@2x.png",980px,350px);
}

.shape-logo-white {
  @include img-retina("../images/logo-full-white.png","../images/logo-full-white@2x.png",980px,350px);

  &.position-top-right {
    opacity: 0.4;
  }
}

.nav-sidebar {
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: $black;
  //background: $nav-bg-color;
  z-index: 1010;

  @media (min-width: $screen-sm-min) {
    width: 60px;
    height: 100%;
  }

  a {
    color: #fff;
    &:hover {
      color: #e6e7e8;
    }
  }

  .home-icon {
    z-index: 1050;
    position: absolute;
    right: 0;
    @media (min-width: $screen-sm-min) {
      left: 0;
      bottom: 0;
    }
  }
}

.nav-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &.overlay-in {
    position: fixed;
    z-index: 50;
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($black, 0.8);
  transition: transform 0.4s cubic-bezier(0.2, 1, 0.3, 1);
  padding: 13px 15px;
  z-index: 1000;
  margin-top: 60px;
  transform: translateY(-100%);
  width: 100%;
  height: calc(100% - 60px);

  &.menu-in {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
  &.menu-hidden {
    opacity: 0;
  }

  @media (min-width: $screen-sm-min) {
    //width: calc(25% - 60px);
    max-width: 210px;
    height: 100%;
    margin-left: 60px;
    margin-top: 0;
    transform: translateX(-100%);
    padding: 23px 30px 30px 30px;
  }

  .navigation {
    .menu-item {
      margin-bottom: 10px;
    }

    a {
      display: inline-block;
      font-size: 28px;
      font-weight: 400;
      line-height: 1;
      font-family: $font-family-heading;
      font-weight: bold;

      &:hover,
      &:focus {
        // color: $brand-primary;
        text-decoration: none;
        // background-repeat: repeat-x;
        // background-image: linear-gradient(to right,#fff000 100%, #fff000 0);
        // background-size: 1px 2px;
        // background-position: 0 1.25em;
        // background-position: 0 calc(1em + 2px);
        color: $black;
        background-color: #fff000;
      }
    }
  }

  .contact-info {
    position: absolute;
    white-space: nowrap;
    bottom: 15px;
    left: 15px;

    @media (min-width: $screen-sm-min) {
      bottom: 15px;
      left: 30px;
    }

    p:last-child {
      margin-bottom: 0;
    }

    .list-social {
      margin-bottom: 0;
    }

    a {
      &:hover {
        color: $black;
      }
    }

    //@media (min-width: $screen-sm-min) {
    //  font-size: $font-size-small;
    //}
  }

  .list-social li {
    a {
      &:hover {
        background-color: transparent;
        color: #fff000;
      }
    }
  }
}

// the menu toggle button wrapper
.menu-toggle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: $black;
  //background: $nav-bg-color;
  z-index: 1010;

  @media (min-width: $screen-sm-min) {
    width: 60px;
    height: 100%;
  }
}

.menu-toggle-button {
  padding: 30px 20px;
  outline: 0 solid transparent;
  background-color: transparent;
  border: 0px solid transparent;
  color: $black;

  @media (min-width: $screen-sm-min) {
    //height: 100%;
    width: 60px;
    //padding: 38px 18px;
  }

  span,
  span:before,
  span:after {
    width: 24px;
    height: 1px;
    background: $white;
    transform: translateZ(0);
    transition: all 0.4s cubic-bezier(0.2, 1, 0.3, 1);
  }

  span {
    display: block;
    position:relative;

    @media (min-width: $screen-sm-min) {
      position: absolute;
      top: 30px;
      left: 18px;
    }
    &:before {
      content:'';
      position:absolute;
      top: -7px;
      left: 0;
    }
    &:after {
      content:'';
      position:absolute;
      top: 7px;
      left: 0;
    }
  }

  &.menu-active span {
    background-color: transparent;

    &:before {
      transform: rotate(45deg);
      top:0;
    }
    &:after {
      transform: rotate(-45deg);
      top:0;
    }
  }

  &:hover {
    span,
    span:before,
    span:after {
      background: #fff000;
    }

    &.menu-active span {
      background-color: transparent;
    }
  }
}


/*
.collapse-icon {
  display: inline-block;
  position:relative;
  width: 24px;
  height: 24px;

  &:before,
  &:after {
    width: 24px;
    height: 1px;
    background: $link-color;
    transition: transform 0.4s cubic-bezier(0.2, 1, 0.3, 1);
  }
  &:before {
    content:'';
    position:absolute;
    top: 12px;
    left: 0;
    transform: rotate(45deg);
  }
  &:after {
    content:'';
    position:absolute;
    top: 12px;
    left: 0;
    transform: rotate(-45deg);
  }
}
*/

.collapse-icon {
  position:relative;
  padding: 12px;

  &:before,
  &:after {
    width: 24px;
    height: 1px;
    background: $link-color;
    transition: transform 0.4s cubic-bezier(0.2, 1, 0.3, 1);
  }
  &:before {
    content:'';
    position:absolute;
    top: 12px;
    left: 0;
    transform: rotate(45deg);
  }
  &:after {
    content:'';
    position:absolute;
    top: 12px;
    left: 0;
    transform: rotate(-45deg);
  }
}


.collapsed .collapse-icon {
  &:before {
    transform: rotate(0deg);
    //top:0;
  }
  &:after {
    transform: rotate(-90deg);
    //top:0;
  }
}

/*
.bio-collapse-toggle a,
.read-more-wrap a {
  color: $black;
  &:hover {
    color: lighten($black, 45%);
  }
}
*/

.bio-collapse-toggle .collapse-icon {
  position: absolute;
  right: 0;
}

.read-more-wrap .collapse-icon {
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
}

// Media
// ================================
.media-container {
  position: relative;
  margin-bottom: 60px;
}

.embed-responsive > iframe {
  @extend .embed-responsive-item;
}

.embed-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  // pointer-events: none;
  transform: translateZ(0);
  transition: transform 0s linear, opacity 0.4s ease-out;

  &.hover,
  &:hover,
  &:focus {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0s linear 0.4s, visibility 0s linear 0.4s, opacity 0.4s ease-out;
    visibility: hidden;
  }
}

.instagram-container {
  @extend .media-container;
  @extend .clearfix;

  .caption {
    color: white;
  }
}

.figure-square {
  padding-top: 100%;

  img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
  }
}


.figure-hover {
  position: relative;
  overflow: hidden;
  background-color: $black;
  transform: translateZ(0);

  &.instagram {
    background-color: $white;

    figcaption {
      &::before {
        top: 0;
        bottom: 0;
      }
    }
  }

  > a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  img {
    opacity: 1;
    transition: opacity 0.6s cubic-bezier(0.2, 1, 0.3, 1);
  }

  figcaption,
  .caption-container {
    z-index: 5;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    // top: 0;
    max-height: 100%;
    overflow: scroll;
    -ms-overflow-style: none;  // IE 10+
    overflow: -moz-scrollbars-none;  // Firefox
    overflow-x: hidden;
    padding: 15px;
    color: $white;
    transform: translateY(100%) translateZ(0);
    transition: transform 0.6s cubic-bezier(0.2, 1, 0.3, 1);
    font-size: $font-size-large;

    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }

    .caption {
      display: none;
      color: white;
      padding: 0;
      font-size: $font-size-large;
      opacity: 0;
      transition: 0.6s ease-in-out;

      p:last-child {
        margin-bottom: 0;
      }

      @media (min-width: $screen-sm-min) {
        display: block;
      }
    }

    a {
      color: $white;
      text-decoration: underline;
      transition: none;

      &:hover {
        color: $black;
        text-decoration: none;
      }
    }
  }

  &:hover,
  > a:hover,
  > a:focus {
    img,
    .ig-portrait,
    .ig-landscape,
    .ig-square {
      opacity: 0.5 !important;
    }

    figcaption,
    .caption-container,
    .fa-instagram {
      transform: translateY(0);
    }

    .caption {
      opacity: 1;
    }
  }
}

.figure-hover-cta {
  position: relative;
  overflow: hidden;
  background-color: $black;
  transform: translateZ(0);

  figcaption,
  .caption-container {
    height: 100%;
    overflow: visible;
    overflow-x: visible;
    pointer-events: none;

    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -60px;
      left: 0;
      right: 0;
      bottom: -60px;
      background-color: rgba($black, 0.667);
      transform: translateY(0);
      transition: all 0.6s cubic-bezier(0.2, 1, 0.3, 1);
    }

    .cta-title {
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      font-family: $font-family-heading;
      font-weight: bold;
      line-height: 30px;
      transform: translateY(0);
      transition: transform 0.6s cubic-bezier(0.2, 1, 0.3, 1);
      padding: 15px 15px;
      margin: 0;

      .cta-title-wrap {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        width: calc(100% - 60px);
        text-overflow: ellipsis;
        vertical-align: middle;
      }

      .cta-icon {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        vertical-align: top;

        svg {
          height: 100%;
          width: auto;
          min-width: 60px;
          padding: 15px 20px 15px 10px;
          fill: $white;
          transition: all 0.2s cubic-bezier(0.2, 1, 0.3, 1);

          .arrow {
            transform: rotate(180deg);
            transform-origin: center;
          }
        }
      }
    }

    .caption {
      position: absolute;
      bottom: 15px;
      right: 15px;
      left: 15px;
      font-size: $font-size-base;

      time {
        font-size: $font-size-small;
      }
    }
  }

  // &.instagram {
  //   figcaption {
  //   }
  // }

  &:hover,
  &:focus-within,
  > a:hover,
  > a:focus {
    img,
    .ig-portrait,
    .ig-landscape,
    .ig-square {
      opacity: 1 !important;
      // transition: opacity 0.6s cubic-bezier(0.2, 1, 0.3, 1) 0.4s;
    }

    figcaption,
    .caption-container,
    .fa-instagram {
      transform: translateY(0);
    }

    figcaption .cta-title {
      transform: translateY(100%);
    }

    .cta-icon svg {
      transition: all 0.4s cubic-bezier(0.2, 1, 0.3, 1);
      background-color: $brand-primary;
      fill: $black;
    }
  }

  // &.instagram,
  // &.bio-portrait {
  //   figcaption,
  //   .caption-container {
  //     overflow: scroll;
  //     overflow-x: hidden;

  //     &::before {
  //       content: none;
  //     }

  //     .caption {
  //       position: static;
  //       bottom: auto;
  //       left: auto;
  //       right: auto;
  //       opacity: 1;
  //       pointer-events: all;
  //       font-size: $font-size-large;
  //     }
  //   }
  // }
}

.figure-project {
  position: relative;
  margin-bottom: 0;

  figcaption.project-grid-meta {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba($black, 0.8);
    padding: 15px;

    h4 {
      display: inline-block;
      font-family: $font-family-heading;
      font-weight: bold;
      color: white;
    }
  }

  > a {
    display: block;

    &:hover {
      background-color: transparent;

      h4 {
        background-color: #fff000;
        color: $black;
      }
    }
  }
}

.bio-portrait {
  .caption-container {
    z-index: 5;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 100%;
    overflow: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    padding: 15px;
    color: #fff;
    transition: transform .6s cubic-bezier(.2,1,.3,1);
    font-size: $font-size-large;
  }
}

// Image Lazyloading
img[loading="lazy"] {
  &,
  .figure-hover & {
    opacity: 0;
    transition: opacity 0.6s cubic-bezier(0.2, 1, 0.3, 1);

    &.is-lazy-loaded {
      opacity: 1;
    }
  }
}

.instagram {
  float: left;
  width: 50%;
  margin: 0;
  padding-top: 50%;

  @media (max-width: $screen-xs-max) {
    display: none;

    &:nth-child(-n+4) {
      display: block;
    }
  }

  @media (min-width: $screen-sm-min) {
    width: 33.333333333%;
    margin: 0;
    padding-top: 33.333333333%;
  }

  .fa-instagram {
    z-index: 10;

    @media (min-width: $screen-sm-min) {
      position: absolute;
      top: 18px;
      left: 15px;
    }
  }

  .caption {
    display: none;

    @media (min-width: $screen-sm-min) {
      display: block;
    }
  }

  figcaption,
  .caption-container {
    @media (min-width: $screen-sm-min) {
      padding-left: 45px;
    }
  }

  .ig-portrait,
  .ig-landscape,
  .ig-square {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) translateZ(0);
    max-width: 100%;
    width: 100%;
  }
  // No cropping
  // .ig-portrait {
  //   max-width: 100%;
  //   width: 100%;
  // }
  // .ig-landscape {
  //   max-height: 100%;
  //   height: 100%;
  //   max-width: none;
  // }
  // .ig-square {
  //   max-width: 100%;
  // }
}

.insta-follow {
  margin-top: 0;
  margin-bottom: 40px;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 60px;
  }
}

// Video Modal Customization
// ============================
.modal-video {
  .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 95%;
    margin: 0;

    &.modal-lg {
      @media (min-width: $screen-md-min) {
        width: 75%;
      }
    }

    .embed-responsive {
      opacity: 0;
      transition: 0.2s ease-in-out;
    }
  }

  &.fade .modal-dialog {
    transform: translate(-50%, -50%);
  }

  &.fade.in .modal-dialog {

    .embed-responsive {
      transition: 0.4s ease-in-out 0.4s;
      opacity: 1;
    }
  }

  .modal-content {
    background-color: transparent;
    box-shadow: none;
    border: none;

    .modal-body {
      padding: 0;
      border-bottom: none;
      box-shadow: none;
    }
  }

  .embed-responsive {
    background-color: $black;
  }
}

.embed-responsive {
  .video::before,
  .video::after {
    content: '';
    display: block;
    position: absolute;
    opacity: 0;
  }

  .video::before {
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top-color: $white;
    transform: translateX(-9000px);
    transition: transform 0s linear 0.4s, opacity 0.4s linear;
    pointer-events: none;
    z-index: 15;
  }

  .video::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $black;
    z-index: 10;
    transform: translateX(-100%);
    transition: transform 0s linear 0.4s, opacity 0.4s linear;
  }

  .video.loading {
    &::before  {
      opacity: 1;
      transform: none;
      transition: transform 0s linear, opacity 0.4s linear;
      animation: spinner .6s linear infinite;
    }

    &::after {
      opacity: 1;
      transform: translateX(0);
      transition: transform 0s linear, opacity 0.4s linear;
    }
  }
}

.embed-responsive-square {
  position: relative;
  height: 100%;
  width: 100%;

  & > iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}

.modal-backdrop.show {
  opacity: 0.9;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

//carousel and modal
.unbutton {
  -webkit-appearance: none;
  cursor: pointer;
  border: 0;
  background: transparent;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.image-button {
  display: block;
  width: 100%;
  //padding: 0;
  background: $white;
  img {
    opacity: 1;
    transition: opacity 0.8s cubic-bezier(0.2, 1, 0.3, 1);
  }
  &:hover {
    img {
      opacity: 0.75;
    }
    .toggle-modal {
      opacity: 1;
    }
  }
  .toggle-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transition: opacity 0.8s cubic-bezier(0.2, 1, 0.3, 1);
    transform: translateX(-50%) translateY(-50%);
    &:before,
    &:after {
      content:'';
      position:absolute;
      left: 0;
      background: $white;
    }
    &:before {
      transform: rotate(0deg);
    }
    &:after {
      transform: rotate(-90deg);
    }
  }
}

.toggle-modal {
  display: block;
  position:relative;
  width: 36px;
  height: 36px;

  @media (min-width: $screen-sm-min) {
    width: 50px;
    height: 50px;
  }
  &:before,
  &:after {
    content:'';
    position:absolute;
    left: 0;
    width: 36px;
    height: 1px;
    background: $black;
    transition: transform 0.4s cubic-bezier(0.2, 1, 0.3, 1);

    @media (min-width: $screen-sm-min) {
      width: 50px;
    }
  }
  &:before {
    top: 18px;
    transform: rotate(45deg);
    @media (min-width: $screen-sm-min) {
      top: 24px;
    }
  }
  &:after {
    top: 18px;
    transform: rotate(-45deg);
    @media (min-width: $screen-sm-min) {
      top: 24px;
    }
  }
}

.modal {
  background-color: white;
  .close {
    z-index: 1010;
    position: fixed;
    top: 15px;
    right: 15px;
    width: 36px;
    height: 36px;
    opacity: 1;
    outline: 0;
    background-color: rgba($white, 0.5);

    @media (min-width: $screen-sm-min) {
      top: 24px;
      right: 24px;
      width: 50px;
      height: 50px;
    }
  }
}

.modal-backdrop {
  background-color: $white;
  &.in { opacity: 1; }
}

.flickity-viewport { max-height: 100%; }

.project-gallery {
  height: 100%;
  .gallery-cell {
    width: 100%;
    height:100%;
    height: 100vh;
    padding: 15px;

    @media (max-width: $screen-xs-max) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    @media (min-width: $screen-sm-min) {
      padding: 30px;
    }
    img {
      display: inline-block;
      max-height: 100%;
      min-width: auto;
      @media (min-width: $screen-sm-min) {
        height: 100%;
        width: auto;
      }
    }
  }
}

//carousel controls {
.flickity-prev-next-button {
  background: transparent;
  width: 60px;
  height: 60px;
  padding: 0;
  border-radius: 0;
  transition: background-color 0.4s cubic-bezier(0.2, 1, 0.3, 1), opacity 0.6s cubic-bezier(0.2, 1, 0.3, 1);

  @media (min-width: $screen-sm-min) {
    width: 100px;
    height: 100px;
  }
  &.previous {
    left: 0;
    svg {
      left: 30%;
    }
  }
  &.next {
    right: 0;
    svg {
      left: 10%;
    }
  }
  &:disabled {
    opacity: 0;
    &:hover {
      background-color: transparent;
    }
  }
  &:hover {
    background-color: rgba($white, 0.8);
  }
  &:focus {
    box-shadow: 0 0 0 1px rgba($white, 0.5);
  }
  .arrow {
    fill: $black;
  }
}

// Utilities
.d-flex {
  display: flex;
}
