.unbutton,
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  outline: 0;
  text-decoration: none;
  border-width: 0;
  padding: 0;
}

.btn-link {
  text-align: left;
  color: $btn-link-color;
}

.home-icon {
  //position:relative;
  //padding: 30px;
  width: 60px;
  height: 60px;
  //border: 1px solid white;

  svg {
    position: absolute;
    //left: 30%;
    //top: 30%;
    //width: 45%;
    //height: 45%;
    left: 15px;
    top: 15px;
    width: 30px;
    height: 30px;
  }

  .home {
    fill: $white;
    stroke: $white;
    stroke-width: 1.5;
    stroke-linecap: square;
  }

  &:hover {
    background-color: transparent;

    .home {
      fill: #fff000;
      stroke: #fff000;
    }
  }
}

button.scroll-down {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
	display: block;
  width: 60px;
  height: 60px;

  svg {
    position: absolute;
    top: 0;
    left:0;
    width: 60px;
    height: 60px;
    transform: scale(0.5) rotate(270deg);
    transform-origin: center center;
  }

  .arrow {
    fill: $white;
  }
}

button.to-top {
  position: fixed;
	display: block;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  background-color: $black;
  opacity: 0;

  svg {
    position: absolute;
    // top: 15px;
    // left: 15px;
    // width: 30px;
    // height: 40px;
    width: 60%;
    height: 80%;
    top: 20%;
    left: 20%;
    fill: $white;
    transform: rotate(90deg);
    transform-origin: center center;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $black;

    svg {
      fill: #fff000;
    }
  }

  .button-to-top-container & {
    position: absolute;
    right: 0;
    bottom: 0;

    // @media (max-width: $screen-xs-max) {
    //   height: 30px;
    //   width: 30px;

    //   svg {
    //     width: 60%;
    //     height: 80%;
    //     top: 20%;
    //     left: 20%;
    //   }
    // }
  }
}

.button-to-top-container {
  z-index: 1040;
  position: sticky;
  height: 0;
  width: 60px;
  bottom: 30px;
  right: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  overflow: visible;

  @media (max-width: $screen-xs-max) {
    position: fixed;
    margin-bottom: 0;
    height: 60px;
    width: 60px;
  }
}

.pin-button {
  display: inline-block;
  vertical-align: bottom;
  //margin: 7px;
  //width: 30px;
  //position: relative;
  //bottom: 35px;
  //left: 15px;
  @media (max-width: $screen-xs-max) {
    position: absolute;
    background-color: white;
    bottom: 0;
    right: 0;
  }
  a {
    .fa {
      padding: 10px;
      font-size: 20px;
    }
    &:hover {
      color: #cb2027;
    }
  }
}

.projects-prev-next-button {
  z-index: 1;
  //position: fixed;
  position: relative;
  display: block;
  //top: 50%;
  width: 100px;
  height: 100px;
  border: none;
  overflow: hidden;
  //transform: translateY(-50%);
  //background: 0 0;
  background-color: rgba($white, 0);
  border-radius: 0;
  transition: background .4s cubic-bezier(.2,1,.3,1), opacity .6s cubic-bezier(.2,1,.3,1), width .4s cubic-bezier(.2,1,.3,1);
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
  }
  &.next {
    right: -20px;
    float: right;
    svg {
      right: 20px;
    }
    .prev-next-title {
      text-align: right;
      right: 100px;
      padding-left: 20px;
    }
  }
  &.previous {
    left: -20px;
    float: left;
    @media (min-width: $screen-sm-min) {
      //left: 60px;
    }
    svg {
      left: 20px;
    }
    .prev-next-title {
      left: 100px;
      padding-right: 20px;
    }
  }
  &:hover {
    background-color: $white;
    width: 300px;
    .prev-next-title {
      opacity: 1;
    }
  }
  .prev-next-title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 200px;
    opacity: 0;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(.2,1,.3,1);
  }
}

.btn-play {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(white, 0.2);
  transition: background-color 0.2s ease-in-out;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  &::before {
    width: 108px;
    height: 65px;
    background-color: rgba($black, 0.8);
    transition: background-color 0.2s ease-in-out;
  }

  &::after {
    // width: 30px;
    border-width: 16px;
    border-style: solid;
    border-left: 28px solid white;
    border-right: none;
    border-top-color: transparent;
    border-bottom-color: transparent;
  }

  &:hover,
  &:focus {
    &::before {
      background-color: $black;
    }
  }
}
