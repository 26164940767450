.content-info {
  z-index: 1010;
  position: relative;
  // transform: translateZ(0);
  // backface-visibility: hidden;
  // -webkit-backface-visibility: hidden;

  background-color: $black;
  color: #fff;

  a {
    color: lighten($link-color, 35%);

    &:hover {
      color: $black;
    }
  }

  address,
  ul,
  p {
    margin-bottom: 13px;
    margin-top: 13px;
  }

  .list-social li {
    line-height: 34px;

    a {
      &:hover {
        background-color: transparent;
        color: #fff000;
      }
    }
  }
}

.contact-info {
  > * {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media ( max-width: $screen-xs-max ) {
    flex-direction: column;

    .list-social {
      order: -1;
    }
  }
}

.copyright-info {
  @media ( max-width: $screen-xs-max ) {
    text-align: left;
  }

  // @media ( max-width: 1608px ) {
  //   padding-right: 90px;
  // }
}
