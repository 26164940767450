.project-image-row {
  @media (min-width: $screen-sm-min) {
    margin-bottom: 30px;
  }
}

.project-image-col {
  margin-bottom: 15px;
  @media (min-width: $screen-sm-min) {
    margin-bottom: 0;
  }

  iframe {
    width: 100%;
  }
}

.project-image {
  width: 100%;
}

.project-header {
  //margin-left: -30px;
  //margin-right: -30px;
  position: relative;
  height: 0;
  padding-top: 66.7%;
  background-size: cover;
  background-position: center center;
  background-color: $alt-text-color;
  @media (min-width: $screen-sm-min) {
    position: fixed;
    height: 100vh;
    margin-bottom: 60px;
    padding: 0;
  }
  .entry-title {
    position: absolute;
    //bottom: 30px;
    //left: 15px;
    margin: 0;

    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    //@media (min-width: $screen-sm-min) {
    //  left: 30px;
    //}
  }
  .header-white {
    color: $white;
  }
  .header-black {
    color: $black;
    text-shadow: 1px 1px 1px rgba(255,255,255,0.004);
  }
  .container-fluid {
    height: 100%;
    position: relative;
  }
}

.type-projects {
  .entry-title {
    margin-bottom: 30px;
  }
}

.meta-list {
  @extend %body-text;

  margin-bottom: 60px;

  .meta-value {
    margin-bottom: 15px;
  }
}

.entry-content,
.entry-photos {
  @extend %body-text;

  line-height: 1.6;

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.6;
  }

  img {
    @extend .img-responsive;

    margin: 0 0 30px;
  }
}

.main .img-responsive
.project-image {
  min-width: 100%;
}

.project-description {
  margin-bottom: 70px;
}

.project-navigation {
  border-bottom: 1px solid $border-color;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.related-projects-section .section-header h2 {
  margin-top: 20px;
  padding-top: 30px;
  border-top: 1px solid $border-color;
}

.related-projects {
  margin-bottom: 15px;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 40px;
  }
}

// News
// ==========================

.article-heading {
  // font-size: $font-size-h3;
}

.articles article {
  &:first-child {
    .article-heading {
      //margin-top: 0;
    }
  }
  &:last-child {
    margin-bottom: 40px;
    @media (min-width: $screen-sm-min) {
      margin-bottom: 60px;
    }
  }
}

// Front Page

.blog .post header {
  margin-bottom: 10px;
}

.news-wrapper {
  margin-top: 40px;
}

section.news {
  .section-header {
    margin-top: 10px;
  }
  // .articles {
  //   margin-top: 20px;
  // }

  .article-heading {
    font-size: 24px;

    time {
      font-size: 66.66667%;
    }
  }
}

body.blog {
  .page-header {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.articles {
  article.post {
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $border-color;

    &:last-child {
      border-bottom: none;
    }

    .updated {
      //font-weight: bold;
      font-size: 14px;
      display: inline-block;
      margin-bottom: 10px;
    }
    figure {
      //margin-bottom: 0;

      a {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    .article-content {
      @extend %body-text;
    }
    &.has-figure .article-content {

    }
    .article-heading {
      margin-top: 10px;
    }
  }
}

.article-images {
  margin-bottom: 40px;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 0;
  }

  .flickity-prev-next-button {
    width: 50px;
    height: 50px;
  }

  .flickity-page-dots {
    text-align: left;

    @media ( min-width: $screen-sm-min ) {
      padding-bottom: 3.25px;
    }
  }
}

.article-carousel {
  white-space: nowrap;
  overflow: hidden;

  .carousel-cell {
    display: inline-block;
  }

  &.flickity-enabled {
    overflow: visible;

    .carousel-cell {
      display: block;
    }
  }

  .flickity-page-dots .dot.is-selected {
    background: #fff000;
  }
}

.article-image-container {
  position: relative;
  width: 100%;
  padding-top: 75%;

  .article-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}

.no-posts {
  font-size: $font-size-large;
}

// Single Post

.single-post,
.single-career {
  .content {
    header {
      margin-top: 40px;

      .entry-title {
        margin-top: 0;
      }
    }

    .entry-content,
    .entry-photos {
      margin-top: 40px;
    }

    footer {
      margin-top: 40px;
    }
  }
}

.back-to-news {
  $font-size: 18px;
  font-size: $font-size;
  //line-height: 30px;
  svg {
    $height: 29px;

    position: relative;
    display: inline-block;
    fill: $black;
    top: ($height - $font-size) * 0.5;
    width: $height;
    height: $height;
    margin-right: 10px;
  }
}

.twitter-share-button {
  margin-bottom: -5px;
  margin-left: 10px;
}

//Careers
.post-career {
  h2 {
    margin-top: 0;
  }
  p:first-child {
    margin-top: 8px;
  }
}

.nav-links {
  @extend %body-text;

  display: flex;
  flex-direction: row;
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid $border-color;

  .nav-previous,
  .nav-next {
    flex: 1;
    max-width: 50%;

    &:last-child a {
      padding-left: 15px;
    }

    &:first-child a {
      padding-left: 0;
    }
  }

  a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
